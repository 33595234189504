import React from 'react'
import Quiz from '../quiz'
import Calculator from '../calculator'

function Sections({ nodes }) {
  if (!nodes) return null

  let components = []

  nodes.forEach((node, i) => {
    switch (node.__typename) {
      case 'ContentfulQuizBlock':
        components.push(<Quiz key={i} quizBlockEntryId={node.contentful_id} />)
        break
      case 'ContentfulCalculatorBlock':
        components.push(<Calculator key={i} />)
      default:
        break
    }
  })

  return <div>{components}</div>
}

export default Sections
