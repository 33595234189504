import React from 'react'

function GameOver({ players, handleNewGame }) {
  return (
    <div className="w-2/4 p-24" aria-live="polite">
      <strong className="block text-center text-4xl mb-10 font-bold">
        Game over
      </strong>
      <div className="px-10 py-10 mb-16 arrow-down bg-purple-600 text-2xl">
        <ol className="list-decimal mx-6">
          {players
            .sort((a, b) => b.score - a.score)
            .map((player, i) => {
              return (
                <li key={i} className="flex justify-between">
                  <span>{player.name}</span>
                  <span>{player.score} poäng</span>
                </li>
              )
            })}
        </ol>
      </div>
      <button
        onClick={handleNewGame}
        className="mt-10 text-2xl bg-white text-gray-900 font-bold py-6 px-10 block mx-auto rounded-full focus:outline-none focus:shadow-light hover:shadow-subtle"
      >
        Nytt spel
      </button>
    </div>
  )
}
export default GameOver
