import React from 'react'
import Breadcrumbs from '../components/breadcrumbs'
import TextBody from '../components/textBody'
// import { TiArrowRight } from 'react-icons/ti'
import { Link } from 'react-scroll'
import Html from '../components/html'
import Quiz from '../components/quiz'
import Sections from '../components/sections'
import Embed from '../components/embed'
import Seo from '../components/seo'

const Page = ({ doc, crumbs }) => {
  let background

  switch (doc.theme) {
    case 'Blue':
      background = 'bg-gradient-to-r to-blue-500 from-blue-300'
      break
    case 'Purple':
      background = 'bg-gradient-to-r to-purple-500 from-purple-300'
      break
    case 'Pink':
      background = 'bg-gradient-to-r to-pink-300 from-pink-500'
      break
    case 'Green':
      background = 'bg-gradient-to-r to-green-300 from-green-500'
      break
    case 'Orange':
      background = 'bg-gradient-to-r to-orange-500 from-orange-300'
      break
    default:
      background = 'bg-gray-100'
      break
  }

  return (
    <main id="innehall" aria-labelledby="main-heading">
      <Seo 
        title={doc.metaTitle}
        description={doc.metaDescription}
        ogImage={doc.ogImage}
        ogType={doc.ogType}
        locale={doc.locale}
      />

      <div className="max-w-screen-xl mx-auto px-6 pt-8 md:px-6 md:pt-32">
        <div className="grid lg:grid-cols-3 lg:gap-12">
          <div className="md:col-span-2">
            <h1
              id="main-heading"
              className="text-4xl leading-none  md:text-6xl mb-6 md:mb-8 font-bold"
            >
              {doc.heading}
            </h1>
            <div className="leading-snug text-2xl md:text-2xl text-gray-800">
              <Html>{doc.introduction}</Html>
            </div>          
          </div>
          <div className="md:col-span-1 relative"></div>
        </div>
      </div>

      {doc.embed && (
        <div className={`${background} mt-12 mb-4 md:mt-24 md:mb-12`}>
          <div className="max-w-screen-xl mx-auto px-6 py-8 md:px-6 md:py-12">
            <Embed embed={doc.embed} />
          </div>
        </div>
      )}

      <div className="max-w-screen-xl mx-auto px-6 pb-8 md:px-6 md:pb-32">
        <div className="grid lg:grid-cols-3 lg:gap-12">
          <div className="md:col-span-2">
            <div className="textbody">{doc.body}</div>
          </div>
          <div className="md:col-span-1 relative"></div>
        </div>
      </div>

      <Sections nodes={doc._doc.sections} />
      {/* <Quiz /> */}
    </main>
  )
}

export default Page
