import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { sampleSize } from 'lodash'
import Html from '../html'
import GameOver from './gameOver'
import {
  disableFocusBehindModal,
  enableFocusBehindModal,
} from '../../util/modalHelper'

function Quiz({ quizBlockEntryId }) {
  const FETCH_LEADERBOARD_URL = '/.netlify/functions/getLeaderBoard'
  const UPDATE_LEADERBOARD_URL = '/.netlify/functions/updateLeaderBoard'

  const [players, setPlayers] = useState(null)

  const quizForm = useRef(null)
  const questionFieldset = useRef(null)
  const overlay = useRef(null)

  const refPlayer1 = useRef(null)
  const refPlayer2 = useRef(null)
  const refPlayer3 = useRef(null)
  const refPlayer4 = useRef(null)

  const [questions, setQuestions] = useState(null)
  const [currentQuestion, setCurrentQuestion] = useState(-1)
  const [currentPlayer, setCurrentPlayer] = useState(-1)
  const [showAnswer, setShowAnswer] = useState(false)
  const [isGameOver, setIsGameOver] = useState(false)
  const [message, setMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [leaderboard, setLeaderboard] = useState([])

  const result = useStaticQuery(quizQuestionsQuery)

  useEffect(() => {
    fetch(FETCH_LEADERBOARD_URL, {
      method: 'POST',
      body: JSON.stringify({ quizBlockEntryId }),
    })
      .then(response => response.json())
      .then(json => {
        setLeaderboard(json)
      })
  }, [isGameOver])

  useEffect(() => {
    if (questions) {
      disableFocusBehindModal(overlay.current)
    } else {
      enableFocusBehindModal(overlay.current)
    }
  }, [questions])

  const handleStartGame = e => {
    e.preventDefault()

    setErrorMessage(null)

    let players = []
    if (refPlayer1.current.value) {
      players.push({ name: refPlayer1.current.value, score: 0 })
      refPlayer1.current.value = null
    }
    if (refPlayer2.current.value) {
      players.push({ name: refPlayer2.current.value, score: 0 })
      refPlayer2.current.value = null
    }
    if (refPlayer3.current.value) {
      players.push({ name: refPlayer3.current.value, score: 0 })
      refPlayer3.current.value = null
    }
    if (refPlayer4.current.value) {
      players.push({ name: refPlayer4.current.value, score: 0 })
      refPlayer4.current.value = null
    }

    if (players.length === 0) {
      setErrorMessage(null)
      setTimeout(() => {
        setErrorMessage('Minst en deltagare krävs för att spela')
      }, 100)

      return
    }

    setPlayers(players)

    let questions = result.allContentfulQuizQuestion.edges.map(({ node }) => {
      let answers = []
      if (node.answer1) {
        answers.push({ answer: node.answer1, isCorrect: node.isCorrect1 })
      }
      if (node.answer2) {
        answers.push({ answer: node.answer2, isCorrect: node.isCorrect2 })
      }
      if (node.answer3) {
        answers.push({ answer: node.answer3, isCorrect: node.isCorrect3 })
      }
      if (node.answer4) {
        answers.push({ answer: node.answer4, isCorrect: node.isCorrect4 })
      }
      return {
        question: node.question,
        answers: answers,
        answerDescription: node.answerDescription
          ? node.answerDescription.childMarkdownRemark.html
          : '',
      }
    })

    questions = sampleSize(questions, 30)

    setQuestions(questions)
    setCurrentQuestion(0)
    setCurrentPlayer(0)

    if (document) {
      document.body.classList.add('overflow-hidden')
    }

    setTimeout(() => {
      if (questionFieldset.current) {
        questionFieldset.current.focus()
      }
    }, 500)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (showAnswer) {
      nextQuestion()
    } else {
      handleAnwser()
    }
  }

  const handleAnwser = () => {
    let correctAnswer = true
    const selectedAnswers = Array.prototype.slice
      .call(quizForm.current.answer)
      .filter(input => input.checked)
      .map(input => parseInt(input.value))

    questions[currentQuestion].answers.forEach((answer, i) => {
      if (answer.isCorrect !== selectedAnswers.includes(i)) {
        correctAnswer = false
        return
      }
    })

    if (correctAnswer) {
      let playersWithUpdatedScore = [...players]
      playersWithUpdatedScore[currentPlayer].score += 1
      setPlayers(playersWithUpdatedScore)
      setMessage('Du svarade rätt! 🎉')
    } else {
      setMessage('Du svarade fel 👀')
    }
    setShowAnswer(true)
  }

  const nextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setShowAnswer(false)
      setMessage(null)
      setCurrentQuestion(currentQuestion + 1)
      setCurrentPlayer(getNextPlayer())
      setTimeout(() => {
        if (questionFieldset.current) {
          questionFieldset.current.focus()
        }
      }, 500)
    } else {
      complete()
    }
  }

  const getNextPlayer = () => {
    if (players.length - 1 === currentPlayer) {
      return 0
    }
    return currentPlayer + 1
  }

  const complete = () => {
    setShowAnswer(false)
    setIsGameOver(true)

    if (document) {
      document.body.classList.remove('overflow-hidden')
    }

    fetch(UPDATE_LEADERBOARD_URL, {
      method: 'POST',
      body: JSON.stringify({ players, quizBlockEntryId }),
    })
  }

  const handleNewGame = () => {
    setIsGameOver(false)
    setPlayers(null)
    setQuestions(null)
    setCurrentPlayer(-1)
    setCurrentQuestion(-1)
    setShowAnswer(false)
    setMessage(null)
    if (document) {
      document.body.classList.remove('overflow-hidden')
    }
  }

  let currentView
  if (currentQuestion >= 0) {
    if (isGameOver) {
      currentView = <GameOver players={players} handleNewGame={handleNewGame} />
    } else {
      currentView = (
        <form
          onSubmit={handleSubmit}
          ref={quizForm}
          className="md:w-2/3 p-6 md:p-24 pt-24 md:pt-0"
        >
          {!showAnswer && (
            <React.Fragment>
              <fieldset aria-live="polite" tabIndex="-1" ref={questionFieldset}>
                <legend className="text-center text-2xl md:text-4xl mb-6 md:mb-10 font-bold">
                  {questions[currentQuestion].question}
                </legend>
                <div className="px-6 md:px-10 py-6 mb-12 md:mb-16 arrow-down bg-purple-600">
                  {questions[currentQuestion].answers.map((answer, i) => {
                    return (
                      <div key={i} className="py-4 flex items-center">
                        {answer.answer && (
                          <React.Fragment>
                            <input
                              type="checkbox"
                              name="answer"
                              id={`answer_${i}`}
                              value={i}
                              className="form-checkbox mr-4 w-6 h-6 focus:outline-none focus:shadow-light hover:shadow-subtle"
                            ></input>
                            <label
                              htmlFor={`answer_${i}`}
                              className="text-xl md:text-2xl"
                            >
                              {answer.answer}
                            </label>
                          </React.Fragment>
                        )}
                      </div>
                    )
                  })}
                </div>
              </fieldset>
              <div className="pb-2">
                <button
                  type="submit"
                  className="mb-32 text-2xl bg-white text-gray-900 font-bold py-6 px-10 block mx-auto rounded-full focus:outline-none focus:shadow-light hover:shadow-subtle"
                >
                  Svara
                </button>
              </div>
            </React.Fragment>
          )}
          {showAnswer && (
            <div aria-live="polite">
              <strong className="block text-center text-2xl md:text-4xl mb-6 md:mb-10 font-bold">
                {questions[currentQuestion].question}
              </strong>
              <div className="px-6 md:px-10 py-10 mb-16 arrow-down bg-purple-600 text-xl md:text-2xl">
                <strong className="block mb-4">{message}</strong>
                <Html>{questions[currentQuestion].answerDescription}</Html>
              </div>
              <div className="pb-2">
                <button
                  type="submit"
                  className="mt-10 mb-32 text-2xl bg-white text-gray-900 font-bold py-6 px-10 block mx-auto rounded-full focus:outline-none focus:shadow-light hover:shadow-subtle"
                >
                  Nästa fråga
                </button>
              </div>
            </div>
          )}
        </form>
      )
    }
  }

  return (
    <section className="bg-purple-300 text-white">
      <noscript>
        <div className="max-w-screen-xl mx-auto px-6 py-12 md:py-32">
          <strong>
            Quizen kräver att du har JavaScript aktiverat i din webbläsare.
          </strong>
        </div>
      </noscript>
      <div className="max-w-screen-xl mx-auto px-6 py-12 md:py-32 flex flex-col items-center no-js-hide">
        <h2
          className="text-4xl mb-3 md:text-6xl md:mb-4 font-bold"
          id="quiz"
          tabIndex="-1"
        >
          Cash Quiz
        </h2>

        {currentQuestion >= 0 && (
          <div
            className="fixed inset-0 flex justify-center bg-purple-300 z-50 overflow-auto pt-16"
            ref={overlay}
          >
            <button
              aria-label="Stäng quizen"
              onClick={handleNewGame}
              className="fixed top-0 right-0 m-6 md:m-10 flex justify-center items-center bg-white rounded-full w-12 h-12 md:w-16 md:h-16 block text-gray-900 text-4xl focus:outline-none focus:shadow-light hover:shadow-subtle"
            >
              <i className="uil uil-times" aria-hidden="true"></i>
            </button>
            {!isGameOver && (
              <div
                className="flex justify-center fixed z-50 bg-blur bottom-0 py-8 text-xl md:text-2xl font-bold bg-purple-600 rounded-t-lg"
                style={{ right: '30px', left: '30px' }}
              >
                {players.map((player, i) => {
                  return (
                    <div
                      key={i}
                      className={`md:mx-6 mx-3 ${
                        i === currentPlayer ? 'border-b-2' : ''
                      }`}
                    >
                      {player.name}
                      {i === currentPlayer && (
                        <span className="sr-only"> (din tur)</span>
                      )}
                      : {player.score}
                    </div>
                  )
                })}
              </div>
            )}

            {currentView}
          </div>
        )}

        <p className="px-8 text-2xl md:text-3xl md:w-1/2 text-center leading-snug">
          Välkommen till Koll på cashen frågespel. Skriv in era deltagarnamn och
          tryck på starta.
        </p>
        <form
          className="mt-10 md:mt-16 w-full md:w-1/3"
          onSubmit={handleStartGame}
        >
          <label htmlFor="namn1" className="block pb-3 text-xl font-bold">
            Deltagare 1:
          </label>
          <input
            ref={refPlayer1}
            type="text"
            placeholder="Skriv ditt namn här"
            id="namn1"
            name="namn1"
            onChange={() => setErrorMessage(null)}
            className="w-full text-xl text-gray-900 p-3 md:p-4 mb-6 rounded focus:outline-none focus:shadow-light hover:shadow-subtle"
          />

          <label htmlFor="namn2" className="block pb-3 text-xl font-bold">
            Deltagare 2:
          </label>
          <input
            ref={refPlayer2}
            type="text"
            placeholder="Skriv ditt namn här"
            id="namn2"
            name="namn2"
            onChange={() => setErrorMessage(null)}
            className="w-full text-xl text-gray-900 p-3 md:p-4 mb-6 rounded focus:outline-none focus:shadow-light hover:shadow-subtle"
          />

          <label htmlFor="namn3" className="block pb-3 text-xl font-bold">
            Deltagare 3:
          </label>
          <input
            ref={refPlayer3}
            type="text"
            placeholder="Skriv ditt namn här"
            id="namn3"
            name="namn3"
            onChange={() => setErrorMessage(null)}
            className="w-full text-xl text-gray-900 p-3 md:p-4 mb-6 rounded focus:outline-none focus:shadow-light hover:shadow-subtle"
          />

          <label htmlFor="namn4" className="block pb-3 text-xl font-bold">
            Deltagare 4:
          </label>
          <input
            ref={refPlayer4}
            type="text"
            placeholder="Skriv ditt namn här"
            id="namn4"
            name="namn4"
            onChange={() => setErrorMessage(null)}
            className="w-full text-xl text-gray-900 p-3 md:p-4 mb-6 rounded focus:outline-none focus:shadow-light hover:shadow-subtle"
          />

          <button
            type="submit"
            className="mt-10 text-2xl bg-white text-gray-900 font-bold block mx-auto rounded-full focus:outline-none focus:shadow-light hover:shadow-subtle"
          >
            <span className="py-6 px-10 block transition-transform duration-200 ease-in-out transform rotate-0 hover:scale-105 hover:rotate-3">
              Starta spelet
            </span>
          </button>

          {errorMessage && (
            <strong
              className="h-20 -mb-20 flex font-normal items-center text-xl justify-center"
              aria-live="assertive"
            >
              {errorMessage}
            </strong>
          )}
        </form>
        {/* <table className="mt-24 w-full text-xl md:w-1/2">
          <caption className="text-3xl md:text-5xl mb-8 font-bold">
            Topplista
          </caption>
          <thead className="sr-only">
            <tr>
              <th scope="col">Placering</th>
              <th scope="col">Namn</th>
              <th scope="col">Poäng</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((item, i) => {
              return (
                <tr key={i} className={i % 2 !== 0 ? `bg-purple-600` : ''}>
                  <td className="w-1 px-4 py-2">{i + 1}. </td>
                  <td className="px-4 py-2">{item.name}</td>
                  <td className="px-4 py-2 text-right">{item.score} poäng</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <span className="w-full block py-6 md:w-1/2">
          Månadens toppspelare, listan återställs den 1:e varje månad.
        </span> */}
      </div>
    </section>
  )
}

export default Quiz

const quizQuestionsQuery = graphql`
  query {
    allContentfulQuizQuestion {
      edges {
        node {
          answer1
          answer2
          answer3
          answer4
          answerDescription {
            childMarkdownRemark {
              html
            }
          }
          isCorrect1
          isCorrect2
          isCorrect3
          isCorrect4
          question
        }
      }
    }
  }
`
